import { Injectable } from '@angular/core';
import { Observable, Subject, of, BehaviorSubject } from 'rxjs';
import { tap, switchMap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { AuthResult } from '../types/auth-result.enum';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private static TOKEN_KEY = 'token';
  private static AUTH_INFO_KEY = 'authInfo';
  private authBaseUri: string;
  private authInfo: any = null;
  private _token: string = null;
  private username: string;
  private password: string;
  private isAuthenticatedSubject: Subject<boolean>;

  constructor(private configService: ConfigService, private http: HttpClient, private router: Router) {
    this.authBaseUri = configService.authBaseUri;
    this.isAuthenticatedSubject = new BehaviorSubject<boolean>(this._token != null);
  }

  get token(): string {
    return this._token;
  }

  get isAuthenticated(): boolean {
    return this._token != null;
  }

  get userDisplayName(): string {
    return this.authInfo != null ? this.authInfo.name : '';
  }

  get isAdvancedUser(): boolean {
    return this.authInfo != null ? this.authInfo.advanced === 'true' : false;
  }

  get isAuthenticatedObservable(): Observable<boolean> {
    return this.isAuthenticatedSubject.asObservable();
  }

  authenticate(username: string, password: string): Observable<AuthResult> {
    this.username = username;
    this.password = password;
    const generateTokenUri = `${this.authBaseUri}/generate/${username}/${password}`;
    return this.http.post(generateTokenUri, null, { responseType: 'text' })
      .pipe(
        tap(token => this.updateToken(token)),
        switchMap((token) => this.verify()),
        catchError(err => {
          this.updateToken(null);
          return of(AuthResult.Failed);
        })
      );
  }

  authenticateFromStorage(): Observable<AuthResult> {
    const token = localStorage.getItem(AuthService.TOKEN_KEY);
    if (token == null) {
      return of(AuthResult.Failed);
    }
    this.updateToken(token);
    return this.verify();
  }

  verify(): Observable<AuthResult> {
    const verifyTokenUri = `${this.authBaseUri}/verify`;
    return this.http.get(verifyTokenUri)
      .pipe(
        tap(info => this.updateAuthInfo(info)),
        switchMap((info: any) => {
          this.updateAuthenticated(true);
          return of(AuthResult.Authenticated);
        }),
        catchError(err => {
          this.updateToken(null);
          return of(AuthResult.Failed);
        })
      );
  }

  clearAuthentication(): void {
    this.updateToken(null);
    this.updateAuthInfo(null);
    this.username = null;
    this.password = null;
    this.updateAuthenticated(false);
  }

  private updateAuthInfo(authInfo: any): void {
    this.authInfo = authInfo;
  }

  private updateToken(token: string): void {
    this._token = token;
    if (token == null) {
      this.updateAuthenticated(false);
      this.router.navigate(['/auth']);
      localStorage.removeItem(AuthService.TOKEN_KEY);
    } else {
      this.router.navigate(['/']);
      localStorage.setItem(AuthService.TOKEN_KEY, token);
    }
  }

  private updateAuthenticated(authenticated: boolean): void {
    this.isAuthenticatedSubject.next(authenticated);
  }

}
