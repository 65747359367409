import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { DeviceManagerService } from '../services/device-manager.service';
import { AudioService } from '../services/audio.service';
import { ConnectionState } from '../types/connection-state.enum';
import { EuiccProfile } from 'src/app/device/model/euicc-profile.model';
import { AddProfileParameters } from '../add-profile-dialog/add-profile-parameters';
import { AuthService } from '../services/auth.service';

interface DisplayItems {
    name: string;
    value: any;
}

@Component({
    selector: 'app-device-details',
    templateUrl: './device-details.component.html',
    styleUrls: ['./device-details.component.css']
})

export class DeviceDetailsComponent implements OnInit, OnDestroy {
    deviceId: string;
    detailsSubscription: Subscription;
    displayItems: DisplayItems[];
    isConnected = false;
    previousConnectionState: ConnectionState;
    adminUser = false;
    protectedNicknames = ['public'];
    prefilledIccid: string;

    // euicc profiles
    showProfiles = false;
    showLoading = false;
    profiles: EuiccProfile[];

    // add profiles
    showAdd = false;
    enableAddNow = false;
    enableAddLater = false;
    showAddingProfile = false;
    showSettingNickname = false;

    // delete profile
    showDelete = false;
    enableDeleteNow = false;
    enableDeleteLater = false;
    showDeletingProfile = false;

    // result dialog
    showResult = false;
    resultMessage = '';
    resultTitle = '';
    resultTitleClass = '';

    constructor(private route: ActivatedRoute,
        private deviceManagerService: DeviceManagerService,
        private audioService: AudioService,
        private authService: AuthService) { }

    ngOnInit(): void {
        this.deviceId = this.route.snapshot.params.deviceId;
        // this.adminUser = this.route.snapshot.queryParams.admin === 'true';
        this.adminUser = this.authService.isAdvancedUser === true;
        this.previousConnectionState = ConnectionState.Connected;
        this.detailsSubscription = this.deviceManagerService.deviceDetails(this.deviceId).subscribe(
            details => {
                this.isConnected = details.connectionState === ConnectionState.Connected;
                // update display data
                const items: DisplayItems[] = [];
                const datePipe = new DatePipe('en-US');
                items.push({ name: 'Device ID', value: details.deviceId });
                items.push({ name: 'Connection state', value: details.connectionState });
                items.push({ name: 'Last connected', value: datePipe.transform(details.connectedTime, 'medium') });
                items.push({ name: 'Connection duration', value: (details.connectedMillis / 1000).toFixed() });
                items.push({ name: 'Idle duration', value: (details.idleMillis / 1000).toFixed() });
                items.push({ name: 'Messages sent', value: details.sentMessageCount });
                items.push({ name: 'Last sent', value: datePipe.transform(details.lastSentTime, 'medium') });
                items.push({ name: 'Messages received', value: details.receivedMessageCount });
                items.push({ name: 'Last received', value: datePipe.transform(details.lastReceivedTime, 'medium') });
                items.push({ name: 'Source IP', value: details.sourceIp });
                items.push({ name: 'Source port', value: details.sourcePort });
                items.push({ name: 'Connected PLMN', value: details.connectedPlmn });
                items.push({ name: 'Enabled ICCID', value: details.enabledIccid });
                items.push({ name: 'Enabled IMSI', value: details.enabledImsi });
                this.displayItems = items;

                if (this.previousConnectionState !== details.connectionState) {
                    if (this.isConnected) {
                        this.playConnectedSound();
                    }
                    this.previousConnectionState = details.connectionState;
                }
            });
    }

    // euicc profiles
    onGetProfiles(): void {
        this.showLoading = true;
        this.deviceManagerService.getProfiles(this.deviceId).subscribe(
            profiles => {
                this.profiles = profiles;
                this.showLoading = false;
                this.showProfiles = true;
            },
            err => {
                this.showLoading = false;
                this.showFailureResult('Get Profiles', 'Failed to get profiles');
            });
    }

    onProfilesCloseClicked(): void {
        this.showProfiles = false;
    }

    onDeleteProfileClicked(iccid: string): void {
        console.log(`onDeleteProfileClicked(${iccid})`);
        this.showProfiles = false;
        this.prefilledIccid = iccid;
        this.showDelete = true;
    }

    // add profile
    onAddProfile(): void {
        this.showAdd = true;
        this.enableAddLater = false;
    }

    onAddNowClicked(params: AddProfileParameters): void {
        console.log('addNowClicked: activationCode=' + params.activationCode);
        this.showAddingProfile = true;
        this.showAdd = false;

        if (params.nickname.length > 0) {
            this.deviceManagerService.addProfileAndSetNickname(this.deviceId, params.activationCode, params.nickname).subscribe(
                iccid => {
                    this.showAddingProfile = false;
                    // this.showSuccessfulResult('Add Profile', '<p>Profile successful added</p><p>ICCID: ' + iccid + '</p>');
                    this.showSuccessfulResult('Add Profile',
                        `<p>Profile successful added</p><p>ICCID: ${iccid}<br/>Nickname: ${params.nickname}</p>`
                    );
                },
                err => {
                    this.showAddingProfile = false;
                    this.showFailureResult('Add Profile', `<p>Failed to add profile</p>`);
                    console.log(err);
                }
            );
        } else {
            this.deviceManagerService.addProfile(this.deviceId, params.activationCode).subscribe(
                iccid => {
                    this.showAddingProfile = false;
                    this.showSuccessfulResult('Add Profile', `<p>Profile successful added</p><p>ICCID: {{iccid}}</p>`);
                    console.log('iccid=' + iccid);
                },
                err => {
                    this.showAddingProfile = false;
                    this.showFailureResult('Add Profile', 'Failed to add profile');
                    console.log(err);
                }
            );
        }
    }

    onAddLaterClicked(params: AddProfileParameters): void {
        console.log('addLaterClicked: activationCode=' + params.activationCode + ' nickname=' + params.nickname);
    }

    onCloseAddClicked(): void {
        this.showAdd = false;
    }

    // delete profile
    onDeleteProfile(): void {
        this.showDelete = true;
        this.enableDeleteLater = false;
    }

    onDeleteNowClicked(iccid: string): void {
        this.prefilledIccid = null;
        console.log('deleteNowClicked: iccid=' + iccid);
        this.showDeletingProfile = true;
        this.deviceManagerService.deleteProfile(this.deviceId, iccid).subscribe(
            result => {
                this.showDeletingProfile = false;
                this.showSuccessfulResult('Delete Profile', 'Profile successful deleted');
                console.log('result=' + result);
            },
            err => {
                this.showDeletingProfile = false;
                this.showFailureResult('Delete Profile', 'Failed to delete profile');
                console.log(err);
            }
        );
    }

    onDeleteLaterClicked(iccid: string): void {
        this.prefilledIccid = null;
        console.log('deleteLaterClicked: iccid=' + iccid);
    }

    onCloseDeleteClicked(): void {
        this.prefilledIccid = null;
        this.showDelete = false;
    }

    onEnableProfileClicked(iccid: string) {
        console.log('enableProfileClicked: iccid=' + iccid);
        this.deviceManagerService.enableProfile(this.deviceId, iccid).subscribe(
            result => {
                console.log('result=' + result);
                this.showLoading = true;
                setTimeout(() => {
                    this.onGetProfiles();
                }, 3000);
            },
            err => {
                console.log(err);
            }
        );
    }

    onDisableProfileClicked(iccid: string) {
        console.log('disableProfileClicked: iccid=' + iccid);
        this.deviceManagerService.disableProfile(this.deviceId, iccid).subscribe(
            result => {
                console.log('result=' + result);
                this.onGetProfiles();
            },
            err => {
                console.log(err);
            }
        );
    }

    onResetDevice() {
        console.log('onResetDevice');
        this.deviceManagerService.resetDevice(this.deviceId);
    }

    showSuccessfulResult(title: string, message: string): void {
        this.showDelete = false;
        this.resultMessage = message;
        this.resultTitle = title;
        this.resultTitleClass = 'alert-success';
        this.showResult = true;
    }

    showFailureResult(title: string, message: string): void {
        this.showDelete = false;
        this.resultMessage = message;
        this.resultTitle = title;
        this.resultTitleClass = 'alert-danger';
        this.showResult = true;
    }

    // audio
    playConnectedSound(): void {
        // this.audioService.playSound('device-connect-alert.wav');
        this.audioService.playSound('device-connected.mp3');
    }

    ngOnDestroy(): void {
        this.detailsSubscription.unsubscribe();
    }
}
