import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './device/services/auth.service';
import { AuthResult } from './device/types/auth-result.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Device Manager';

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.authenticateFromStorage()
      .subscribe(result => {
        if (result === AuthResult.Failed) {
          this.router.navigate(['/auth']);
        }
      });
  }

}
