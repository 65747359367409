import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AudioService {
  private readonly AUDIO_DIRECTORY = '../../../assets/sounds/';

  constructor() {
    this.initialize();
  }

  playSound(file: string): void {
    const audio = new Audio();
    audio.src = this.AUDIO_DIRECTORY + file;
    audio.load();
    audio.play();
  }

  private initialize(): void {
  }

}
