<div class="col-md-10">
    <h3>Devices List</h3>
    <table class="table table-bordered table-striped">
        <thead>
            <tr class="d-flex">
                <th class="col-md-4">Device ID</th>
                <th class="col-md-3">Status</th>
                <th class="col-md-5">Last Connected</th>
            </tr>
        </thead>
        <tbody>
            <tr class="d-flex" *ngFor="let deviceSummary of devicesSummary">
                <td class="col-md-4"> <a [routerLink]="['/device', deviceSummary.deviceId]" style="cursor: pointer;">
            {{deviceSummary.deviceId}}
          </a> </td>
                <td class="col-md-3">{{deviceSummary.connectionState}}</td>
                <td class="col-md-5">{{deviceSummary.connected | date:'medium'}}</td>
            </tr>
        </tbody>
    </table>
</div>