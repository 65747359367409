import { ConnectionState } from '../types/connection-state.enum';

export class DeviceDetails {
  deviceId: string;
  connectionState: ConnectionState;
  sourceIp: string;
  sourcePort: number;
  connectedTime: Date;
  lastSentTime: Date;
  lastReceivedTime: Date;
  idleMillis: number;
  connectedMillis: number;
  sentMessageCount: number;
  receivedMessageCount: number;
  connectedPlmn: string;
  enabledIccid: string;
  enabledImsi: string;
}
