<nav class="navbar navbar-expand-md navbar-dark bg-dark">

    <a class="navbar-brand" routerLink="/">Device Manager</a>
    <div class="navbar-nav mr-auto">
        <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <a class=" nav-link" routerLink="/">Devices</a>
        </li>
        <!-- <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <a class=" nav-link" routerLink="/device">Servers Health</a>
        </li>
        <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <a class="nav-item nav-link" routerLink="/device">Other Stuff</a>
        </li> -->
    </div>
    <div class="nav navbar-nav mr-4 text-primary">
        <li class="nav-item">
            <span class="nav-text">{{userDisplayName}}</span>
        </li>
    </div>
    <div class="nav navbar-nav">
        <li class="nav-item">
            <a class="nav-link" (click)="logout()" [hidden]="!isAuthenticated" style="cursor: pointer;">Logout</a>
        </li>
    </div>
</nav>