<div class="col-md-10">
    <h3>Login</h3>
    <form (ngSubmit)="login(form)" #form="ngForm" class="col-md-6">
        <div class="form-group">
            <label for="username">Username</label>
            <input type="text" class="form-control" id="username" name="username" placeholder="Enter username" ngModel required>
        </div>
        <div class="form-group">
            <label for="password">Password</label>
            <input type="password" class="form-control" id="password" name="password" placeholder="Password" ngModel required>
        </div>
        <button type="submit" class="btn btn-primary" [disabled]="!form.valid">Login</button>
    </form>
    <div *ngIf="msg" class="alert alert-danger col-md-6 ml-2 mt-4">
        {{msg}}
    </div>

</div>