<div class="modal show" tabindex="-1" *ngIf="show">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header alert-secondary">
                <span class="modal-title ">Delete Profile (Device: {{deviceId}})</span>
                <button type="button " class="close" (click)="onCloseClicked()"> <span>&times;</span> </button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="form-group">
                        <label for="iccid">ICCID</label>
                        <input #iccid type="text" #iccidField class="form-control" id="iccid" value="{{prefilledIccid}}" [disabled]="prefilledIccid">
                    </div>
                    <div class="btn-toolbar">
                        <div class="btn-group mr-2">
                            <button type="submit" (click)="onDeleteNowClicked(iccid.value)" [disabled]="!enableDeleteNow" class="btn btn-danger">Delete Now</button>
                        </div>
                        <div class="btn-group mr-2">
                            <button type="submit" (click)="onDeleteLaterClicked(iccid.value)" [disabled]="!enableDeleteLater" class="btn btn-danger">Delete Later</button>
                        </div>
                        <div class="btn-group ml-auto">
                            <button type="button" (click)="onCloseClicked()" class="btn btn-secondary border">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>