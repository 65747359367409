<div class="col-md-8">
    <h3>Device Details</h3>
    <div class="card">
        <div class="card-header">Device: {{deviceId}}</div>
        <div class="card-body">
            <div *ngIf="!displayItems">
                <h4>Loading ...</h4>
            </div>
            <div *ngIf="displayItems">
                <div *ngFor="let item of displayItems" class="row">
                    <div class="col-md-4">{{item.name}}:</div>
                    <div class="">{{item.value}}</div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="btn-toolbar">
                <div class="btn-group mr-2">
                    <button [disabled]="!isConnected" (click)="onGetProfiles() " class="btn btn-primary ">Get Profiles</button>
                </div>
                <div class="btn-group mr-2">
                    <button class="btn btn-primary" (click)="onAddProfile()">Add Profile</button>
                </div>
                <div class="btn-group mr-2">
                    <button class="btn btn-danger" (click)="onDeleteProfile()">Delete Profile</button>
                </div>
                <div class="btn-group mr-2">
                    <button class="btn btn-warning" (click)="onResetDevice()">Reset Device</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- EUICC Profiles -->
<app-euicc-profiles-dialog [profiles]="profiles" [show]="showProfiles" [deviceId]="deviceId" [protectedNicknames]="protectedNicknames" [allowDeleteProtected]="adminUser" (closeClicked)="onProfilesCloseClicked()" (deleteProfileClicked)="onDeleteProfileClicked($event)" (enableProfileClicked)="onEnableProfileClicked($event)" (disableProfileClicked)="onDisableProfileClicked($event)"></app-euicc-profiles-dialog>
<app-loading-dialog [show]="showLoading" [message]="'Fetching profile data ...'" [spinner]="true"></app-loading-dialog>

<!-- result -->
<app-ok-dialog [title]="resultTitle" [htmlMessage]="resultMessage" [titleClass]="resultTitleClass" [(show)]="showResult"></app-ok-dialog>

<!-- Add Profile -->
<app-add-profile-dialog (addNowClicked)="onAddNowClicked($event)" (addLaterClicked)="onAddLaterClicked($event)" (closeClicked)="onCloseAddClicked()" [show]="showAdd" [deviceId]="deviceId" [enableAddNow]="isConnected" [enableAddLater]="enableAddLater"></app-add-profile-dialog>
<app-loading-dialog [show]="showAddingProfile" [message]="'Adding profile ...'" [spinner]="true"></app-loading-dialog>
<app-loading-dialog [show]="showSettingNickname" [message]="'Setting nickname ...'" [spinner]="true">
</app-loading-dialog>

<!-- Delete Profile -->
<app-delete-profile-dialog (deleteNowClicked)="onDeleteNowClicked($event)" (deleteLaterClicked)="onDeleteLaterClicked($event)" (closeClicked)="onCloseDeleteClicked()" [show]="showDelete" [deviceId]="deviceId" [enableDeleteNow]="isConnected" [enableDeleteLater]="enableDeleteLater"
    [prefilledIccid]="prefilledIccid">
</app-delete-profile-dialog>
<app-loading-dialog [show]="showDeletingProfile" [message]="'Deleting profile ...'" [spinner]="true">
</app-loading-dialog>
