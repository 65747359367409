import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { filter, mergeAll, tap } from 'rxjs/operators';
import { webSocket, WebSocketSubject, WebSocketSubjectConfig } from 'rxjs/webSocket';
import { EuiccProfile } from '../model/euicc-profile.model';
import { DeviceDetails } from '../model/device-details.model';
import { DeviceSummary } from '../model/device-summary.model';
import { ConfigService } from './config.service';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class DeviceManagerService {
    private managerEventWsUri: string;
    private uiBaseUri: string;
    private managerEventsWebsocket: WebSocketSubject<any>;
    private deviceChangeSubject: Subject<string>;
    private deviceDetailsSubject: Subject<DeviceDetails>;
    devicesSummary: DeviceSummary[];

    constructor(private http: HttpClient, private configService: ConfigService, private authService: AuthService) {
        this.uiBaseUri = configService.uiBaseUri;
        this.managerEventWsUri = configService.managerEventWsUri;

        this.devicesSummary = [];
        // this.managerEventsWebsocket = this.createNewManageEventsWs();
        this.deviceChangeSubject = new Subject();
        this.deviceDetailsSubject = new Subject();
        this.initialize();
    }

    deviceChangeNotification(deviceId: string): Observable<string> {
        const observer = this.deviceChangeSubject.asObservable();
        return observer.pipe(filter(id => id === deviceId));
    }

    deviceDetails(deviceId: string): Observable<DeviceDetails> {
        // force a fresh value in to the subject
        this.requestAndPublishDeviceDetails(deviceId);

        return this.deviceDetailsSubject.asObservable().pipe(
            filter(details => details.deviceId === deviceId)
        );
    }

    getProfiles(deviceId: string): Observable<EuiccProfile[]> {
        const url = `${this.uiBaseUri}/device/${deviceId}/profiles`;
        return this.http.get<EuiccProfile[]>(url);
    }

    addProfile(deviceId: string, activationCode: string): Observable<string> {
        const url = `${this.uiBaseUri}/device/${deviceId}/profile/activationCode/${activationCode}`;
        console.log(url);
        return this.http.post(url, null, { responseType: 'text' });
    }

    addProfileAndSetNickname(deviceId: string, activationCode: string, nickname: string): Observable<string> {
        const url = `${this.uiBaseUri}/device/${deviceId}/profile/activationCode/${activationCode}/nickname/${nickname}`;
        console.log(url);
        // return this.http.post<string>(url, null);
        return this.http.post(url, null, { responseType: 'text' });
    }

    deleteProfile(deviceId: string, iccid: string): Observable<boolean> {
        const url = `${this.uiBaseUri}/device/${deviceId}/profile/iccid/${iccid}`;
        console.log(url);
        return this.http.delete<boolean>(url);
    }

    setNickname(deviceId: string, iccid: string, nickname: string): Observable<boolean> {
        const url = `${this.uiBaseUri}/device/${deviceId}/profile/iccid/${iccid}/nickname/${nickname}`;
        console.log(url);
        return this.http.put<boolean>(url, null);
    }

    enableProfile(deviceId: string, iccid: string): Observable<boolean> {
        const url = `${this.uiBaseUri}/device/${deviceId}/profile/iccid/${iccid}/state/ENABLED`;
        console.log(url);
        return this.http.put<boolean>(url, null);
    }

    disableProfile(deviceId: string, iccid: string): Observable<boolean> {
        const url = `${this.uiBaseUri}/device/${deviceId}/profile/iccid/${iccid}/state/DISABLED`;
        console.log(url);
        return this.http.put<boolean>(url, null);
    }

    resetDevice(deviceId: string): void {
        const url = `${this.uiBaseUri}/device/${deviceId}/reset`;
        console.log(url);
        this.http.post<void>(url, null).subscribe();
    }

    private initialize(): void {
        // this.fetchDevicesSummary();
        this.requestDetailsOnDeviceChange();

        this.authService.isAuthenticatedObservable.subscribe(
            authenticated => {
                console.log('DeviceManagerService', 'initialize', 'authenticated:', authenticated);
                if (authenticated) {
                    this.managerEventsWebsocket = this.createNewManageEventsWs();
                    this.fetchDevicesSummary();
                    this.watchManagerEvents();
                } else {
                    this.managerEventsWebsocket?.unsubscribe();
                }
            }
        );
    }

    private watchManagerEvents(): void {
        this.managerEventsWebsocket.asObservable().subscribe(ev => {
            const eventType = ev.type;
            switch (eventType) {
                case 'DEVICE_SUMMARY_CHANGE':
                    this.fetchDevicesSummary();
                    break;
                case 'DEVICE_CHANGE':
                    this.deviceChangeSubject.next(ev.deviceId);
                    break;
                default: console.log('Unsupported event type: ' + eventType);
            }
        });
    }

    private requestDetailsOnDeviceChange(): void {
        this.deviceChangeSubject.subscribe(
            deviceId => this.requestAndPublishDeviceDetails(deviceId)
        );
    }

    private requestAndPublishDeviceDetails(deviceId: string): void {
        this.fetchDeviceDetails(deviceId).subscribe(
            details => this.deviceDetailsSubject.next(details)
        );

    }
    private createNewManageEventsWs(): WebSocketSubject<any> {
        // return webSocket(this.managerEventWsUri);
        return webSocket(`${this.managerEventWsUri}?token=${this.authService.token}`);
    }

    private fetchDevicesSummary(): void {
        this.http.get(`${this.uiBaseUri}/device`)
            .pipe(
                tap(() => this.devicesSummary.splice(0, this.devicesSummary.length)),
                mergeAll(),
                this.mapToDeviceSummary()
            )
            .subscribe(summary => this.devicesSummary.push(summary));
    }

    private mapToDeviceSummary = () => (source: Observable<any>) =>
        new Observable<DeviceSummary>(observer => {
            return source.subscribe({
                next(content): void {
                    const summary = new DeviceSummary();
                    summary.deviceId = content.deviceId;
                    summary.connectionState = content.connectionState;
                    summary.connected = new Date(content.connectedTimestamp);
                    observer.next(summary);
                },
                error(err): void { observer.error(err); },
                complete(): void { observer.complete(); }
            });
        })

    private fetchDeviceDetails(deviceId: string): Observable<DeviceDetails> {
        return this.http.get(`${this.uiBaseUri}/device/${deviceId}`)
            .pipe(this.mapToDeviceDetails());
    }

    private mapToDeviceDetails = () => (source: Observable<any>) =>
        new Observable<DeviceDetails>(observer => {
            return source.subscribe({
                next(content): void {
                    const details = new DeviceDetails();
                    details.deviceId = content.deviceId;
                    details.connectionState = content.connectionState;
                    details.sourceIp = content.sourceIp;
                    details.sourcePort = content.sourcePort;
                    details.connectedTime = new Date(content.connectedTimestamp);
                    details.lastSentTime = new Date(content.lastSentTimestamp);
                    details.lastReceivedTime = new Date(content.lastReceivedTimestamp);
                    details.idleMillis = content.idleMillis;
                    details.connectedMillis = content.connectedMillis;
                    details.sentMessageCount = content.sentMessageCount;
                    details.receivedMessageCount = content.receivedMessageCount;
                    details.connectedPlmn = content.connectedPlmn;
                    details.enabledIccid = content.enabledIccid;
                    details.enabledImsi = content.enabledImsi;
                    observer.next(details);
                },
                error(err): void { observer.error(err); },
                complete(): void { observer.complete(); }
            });
        })

}
