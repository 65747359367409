import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.css']
})
export class LoadingDialogComponent {
  @Input() show = false;
  @Input() message = 'Loading ...';
  @Input() spinner = false;

  constructor() { }
}
