import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-delete-profile-dialog',
  templateUrl: './delete-profile-dialog.component.html',
  styleUrls: ['./delete-profile-dialog.component.css']
})
export class DeleteProfileDialogComponent {
  @Input() show = true;
  @Input() deviceId = '';
  @Input() prefilledIccid: string;
  @Input() enableDeleteNow = false;
  @Input() enableDeleteLater = false;
  @Output() deleteNowClicked = new EventEmitter<string>();
  @Output() deleteLaterClicked = new EventEmitter<string>();
  @Output() closeClicked = new EventEmitter();

  constructor() { }

  onDeleteNowClicked(iccid: string): void {
    console.log('onDeleteNowClicked:', iccid);
    this.deleteNowClicked.emit(iccid);
  }

  onDeleteLaterClicked(iccid: string): void {
    this.deleteLaterClicked.emit(iccid);
  }

  onCloseClicked(): void {
    this.closeClicked.emit();
  }

}
