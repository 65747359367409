import { Component, OnInit } from '@angular/core';
import { AuthService } from '../device/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userDisplayName = '';

  get isAuthenticated(): boolean {
    return this.authService.isAuthenticated;
  }
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.watchForNameChanges();
  }

  logout(): void {
    if (this.isAuthenticated) {
      this.authService.clearAuthentication();
    }
  }

  private watchForNameChanges(): void {
    this.authService.isAuthenticatedObservable.subscribe(authenticated => {
      this.userDisplayName = this.authService.userDisplayName;
    });
  }

}
