<div class="modal show" tabindex="-1" *ngIf="show" [ngStyle]="{'display': 'block'}">
    <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
            <div class="card">
                <div [ngClass]="titleClass" class="card-header alert-success">{{title}}</div>
                <div class="card-body">
                    <div [innerHTML]="htmlMessage"></div>
                </div>
                <div class="card-footer">
                    <div class="btn-toolbar ml-auto justify-content-center">
                        <button type="button" (click)="onOkClicked()" class="btn btn-primary px-4 mr-2">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>