import { Component, Input } from '@angular/core';
import {EuiccProfile} from '../model/euicc-profile.model';

@Component({
  selector: 'app-euicc-profile-item',
  templateUrl: './euicc-profile-item.component.html',
  styleUrls: ['./euicc-profile-item.component.css']
})
export class EuiccProfileItemComponent {
  @Input() profile: EuiccProfile = new EuiccProfile();

  constructor() { }
}
