import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Routes, RouterModule, ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { DeviceListComponent } from './device/device-list/device-list.component';
import { DeviceDetailsComponent } from './device/device-details/device-details.component';
import { HeaderComponent } from './header/header.component';
import { EuiccProfileItemComponent } from './device/euicc-profile-item/euicc-profile-item.component';
import { LoadingDialogComponent } from './shared/loading-dialog/loading-dialog.component';
import { AddProfileDialogComponent } from './device/add-profile-dialog/add-profile-dialog.component';
import { EuiccProfilesDialogComponent } from './device/euicc-profiles-dialog/euicc-profiles-dialog.component';
import { DeleteProfileDialogComponent } from './device/delete-profile-dialog/delete-profile-dialog.component';
import { AuthComponent } from './auth/auth.component';
import { OkDialogComponent } from './shared/ok-dialog/ok-dialog.component';
import { AuthInterceptorService } from './device/services/auth-interceptor.service';
import { AuthGuard } from './device/services/auth-guard';

const appRoutes: Routes = [
  { path: '', component: DeviceListComponent, canActivate: [AuthGuard] },
  { path: 'device/:deviceId', component: DeviceDetailsComponent, canActivate: [AuthGuard] },
  { path: 'auth', component: AuthComponent },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  declarations: [
    AppComponent,
    DeviceListComponent,
    DeviceDetailsComponent,
    HeaderComponent,
    EuiccProfileItemComponent,
    LoadingDialogComponent,
    AddProfileDialogComponent,
    EuiccProfilesDialogComponent,
    DeleteProfileDialogComponent,
    OkDialogComponent,
    AuthComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
