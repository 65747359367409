import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  private readonly EVENT_WS_PATH = '/events';
  private readonly UI_BASE_PATH = '/api/ui';
  private readonly AUTH_BASE_PATH = '/api/auth';
  private _managerEventWsUri: string;
  private _uiBaseUri: string;
  private _authBaseUri: string;

  constructor() {
    if (environment.production) {
      this._uiBaseUri = `${location.origin}${this.UI_BASE_PATH}`;
      this._authBaseUri = `${location.origin}${this.AUTH_BASE_PATH}`;
    } else {
      this._uiBaseUri = `${environment.serverBaseHref}${this.UI_BASE_PATH}`;
      this._authBaseUri = `${environment.serverBaseHref}${this.AUTH_BASE_PATH}`;
    }
    this._managerEventWsUri = this._uiBaseUri.replace('http', 'ws') + this.EVENT_WS_PATH;
  }

  get managerEventWsUri(): string  {
    return this._managerEventWsUri;
  }

  get uiBaseUri(): string  {
    return this._uiBaseUri;
  }

  get authBaseUri(): string  {
    return this._authBaseUri;
  }
}
