import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EuiccProfile } from 'src/app/device/model/euicc-profile.model';
import { ProfileState } from '../types/profile-state.enum';

@Component({
    selector: 'app-euicc-profiles-dialog',
    templateUrl: './euicc-profiles-dialog.component.html',
    styleUrls: ['./euicc-profiles-dialog.component.css']
})
export class EuiccProfilesDialogComponent {
    @Input() show = false;
    @Input() deviceId = '';
    @Input() profiles: EuiccProfile[] = [];
    @Input() protectedNicknames: string[] = [];
    @Input() allowDeleteProtected = false;
    @Output() closeClicked = new EventEmitter();
    @Output() deleteProfileClicked = new EventEmitter<string>();
    @Output() enableProfileClicked = new EventEmitter<string>();
    @Output() disableProfileClicked = new EventEmitter<string>();

    constructor() { }

    onCloseClicked(): void {
        this.closeClicked.emit();
    }

    onDeleteProfileClicked(iccid: string): void {
        this.deleteProfileClicked.emit(iccid);
    }

    canDelete(profile: EuiccProfile): boolean {
        // cannot delete enabled profile
        if (profile.state === ProfileState.Enabled) {
            return false;
        }

        // allow special users to delete any profile
        if (this.allowDeleteProtected) {
            return true;
        }

        // do not allow protected profiles to be deleted
        return !this.protectedNicknames.includes(profile.providerNickname);
    }

    onEnableProfileClicked(iccid: string): void {
        this.enableProfileClicked.emit(iccid);
    }

    canEnable(profile: EuiccProfile): boolean {
        return profile.state === ProfileState.Disabled;
    }

    onDisableProfileClicked(iccid: string): void {
        this.disableProfileClicked.emit(iccid);
    }

    canDisable(profile: EuiccProfile): boolean {
        return profile.state === ProfileState.Enabled;
    }

}
