import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AddProfileParameters } from './add-profile-parameters';

@Component({
  selector: 'app-add-profile-dialog',
  templateUrl: './add-profile-dialog.component.html',
  styleUrls: ['./add-profile-dialog.component.css']
})
export class AddProfileDialogComponent {
  @Input() show = true;
  @Input() deviceId = '';
  @Input() enableAddNow = false;
  @Input() enableAddLater = false;
  @Output() addNowClicked = new EventEmitter<AddProfileParameters>();
  @Output() addLaterClicked = new EventEmitter<AddProfileParameters>();
  @Output() closeClicked = new EventEmitter();
  nickname = 'private';

  constructor() { }

  onNicknameChange(name: string): void {
    this.nickname = name;
  }

  onAddNowClicked(activationCode: string): void {
    this.addNowClicked.emit({ activationCode, nickname: this.nickname });
  }

  onAddLaterClicked(activationCode: string): void {
    this.addLaterClicked.emit({ activationCode, nickname: this.nickname });
  }

  onCloseClicked(): void {
    this.closeClicked.emit();
  }

}
