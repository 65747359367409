import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DeviceManagerService } from '../services/device-manager.service';
import { DeviceSummary } from '../model/device-summary.model';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.css']
})
export class DeviceListComponent implements OnInit, OnDestroy {
  devicesSummary: DeviceSummary[];
  // deviceChange: Subscription;

  constructor(
    private deviceGatewayService: DeviceManagerService) {
    this.devicesSummary = [];
  }

  ngOnInit(): void {
    this.devicesSummary = this.deviceGatewayService.devicesSummary;
    // this.deviceChange = this.deviceGatewayService.deviceChangeNotification('12345').subscribe(
    //   id => console.log('Device ' + id + ' changed')
    // );
  }
  ngOnDestroy(): void {
    // this.deviceChange.unsubscribe();
  }

}
