import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { timeout } from 'rxjs/operators';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.token;
    if (token == null) {
      // globally increased HTTP timeout to 60 seconds
      return next.handle(req).pipe(timeout(60000));
    } else {
      const headers = new HttpHeaders().append('Authorization', `Bearer ${token}`);
      const requestWithToken = req.clone({ headers });
      return next.handle(requestWithToken);
    }
  }

}
