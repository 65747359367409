import { Component } from '@angular/core';
import { AuthService } from '../device/services/auth.service';
import { AuthResult } from '../device/types/auth-result.enum';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent {
  msg: string = null;

  constructor(
    private authService: AuthService) {
  }

  login(form: NgForm): void {
    this.msg = '';
    const username = form.value.username;
    const password = form.value.password;
    this.authService.authenticate(username, password).subscribe(
      result => {
        console.log('DeviceManagerService', 'constructor', 'authenticate-result:', result);
        if (result === AuthResult.Failed) {
          this.msg = 'Username or password incorrect';
        }
      }
    );
  }

}
