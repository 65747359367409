import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-ok-dialog',
  templateUrl: './ok-dialog.component.html',
  styleUrls: ['./ok-dialog.component.css']
})
export class OkDialogComponent {
  @Input() title = '';
  @Input() htmlMessage = '';
  @Input() show = false;
  @Input() titleClass = 'alert-secondary';
  @Input() autoClose = true;
  @Output() okClicked = new EventEmitter();
  @Output() showChange = new EventEmitter();

  onOkClicked(): void {
    if (this.autoClose) {
      this.show = false;
      // used for two-way binding: [(show)]
      this.showChange.emit(false);
    }
    this.okClicked.emit();
  }
}
