import { ProfileState } from '../types/profile-state.enum';
import { ProfileClass } from '../types/profile-class.enum';
import { ProfileOwner } from './profile-owner.model';

export class EuiccProfile {
  iccid: string;
  isdpAid: string;
  name: string;
  providerName: string;
  providerNickname: string;
  state: ProfileState;
  profileClass: ProfileClass;
  profileOwner: ProfileOwner;
}
