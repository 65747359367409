<div class="modal show" tabindex="-1" *ngIf="show">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content ">
            <div class="modal-header alert-secondary">
                <span class="modal-title ">EUICC Profiles (Device: {{deviceId}})</span>
                <button type="button " class="close" (click)="onCloseClicked()"> <span>&times;</span> </button>
            </div>
            <div class="modal-body">
                <div *ngFor="let profile of profiles; index as i;" class="panel panel-default">
                    <div class="card-header">
                        <span>Profile #{{i + 1}}</span>
                        <span *ngIf="canDelete(profile)" class="float-right"><button class="btn btn-danger btn-sm" (click)="onDeleteProfileClicked(profile.iccid)">Delete</button></span>
                        <span *ngIf="canEnable(profile)" class="float-right"><button class="btn btn-info btn-sm" (click)="onEnableProfileClicked(profile.iccid)">Enable</button></span>
                        <span *ngIf="canDisable(profile)" class="float-right"><button class="btn btn-warning btn-sm" (click)="onDisableProfileClicked(profile.iccid)">Disable</button></span>
                    </div>
                    <div class="card-body">
                        <app-euicc-profile-item [profile]="profile"></app-euicc-profile-item>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" (click)="onCloseClicked()" class="btn btn-secondary">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>