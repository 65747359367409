<div class="modal show" tabindex="-1" *ngIf="show">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="card">
                <!-- <div class="card-header  alert-secondary">Add Profile (Device: {{deviceId}})</div> -->
                <div class="card-header  alert-secondary">
                    <span class="modal-title ">Add Profile (Device: {{deviceId}})</span>
                    <button type="button " class="close" (click)="onCloseClicked()"> <span>&times;</span> </button>
                </div>
                <div class="card-body">
                    <form>
                        <div class="container">
                            <div class="form-group row">
                                <label for=" activationCode ">Activation Code:</label>
                                <input type="text " #activationCode class="form-control " id="activationCode ">
                            </div>
                            <div class="form-group">
                                <label class="row">Type:</label>
                                <div class="form-check row ml-1">
                                    <input class="form-check-input" type="radio" name="nickname" id="private" (click)="onNicknameChange('private')" checked>
                                    <label class="form-check-label" for="private">Private</label>
                                </div>
                                <div class="form-check row ml-1">
                                    <input class="form-check-input" type="radio" name="nickname" id="public" (click)="onNicknameChange('public')">
                                    <label class="form-check-label" for="public">Public</label>
                                </div>
                                <div class="form-check row ml-1">
                                    <input class="form-check-input" type="radio" name="nickname" id="custom" #custom (click)="onNicknameChange(customNickname.value)">
                                    <label class="form-check-label" for="custom"><input type="text " placeholder="Enter custom type here" #customNickname
                        class="form-control form-control-sm" (blur)="onNicknameChange(customNickname.value)" (focus)="custom.checked='checked'"></label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-footer">
                    <div class="btn-toolbar ">
                        <div class="btn-group mr-2 ">
                            <button type="button" (click)="onAddNowClicked(activationCode.value) " [disabled]="!enableAddNow " class="btn btn-primary ">Add Now</button>
                        </div>
                        <div class="btn-group mr-2 ">
                            <button type="button" (click)="onAddLaterClicked(activationCode.value) " [disabled]="!enableAddLater " class="btn btn-primary ">Add Later</button>
                        </div>
                        <div class="btn-group ml-auto ">
                            <button type="button " (click)="onCloseClicked() " class="btn btn-secondary border">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>