<div class="row">
    <div class="col-md-3">Name:</div>
    <div class="">{{profile.name}}</div>
</div>
<div class="row">
    <div class="col-md-3">Nickname:</div>
    <div class="">{{profile.providerNickname}}</div>
</div>
<div class="row">
    <div class="col-md-3">State:</div>
    <div class="">{{profile.state}}</div>
</div>
<div class="row">
    <div class="col-md-3">ICCID:</div>
    <div class="">{{profile.iccid}}</div>
</div>
<div class="row">
    <div class="col-md-3">MCC:</div>
    <div class="">{{profile.profileOwner.mcc}}</div>
</div>
<div class="row">
    <div class="col-md-3">MNC:</div>
    <div class="">{{profile.profileOwner.mnc}}</div>
</div>
<div class="row">
    <div class="col-md-3">GID1:</div>
    <div class="">{{profile.profileOwner.gid1}}</div>
</div>
<div class="row">
    <div class="col-md-3">GID2:</div>
    <div class="">{{profile.profileOwner.gid2}}</div>
</div>
<div class="row">
    <div class="col-md-3">ISD-P AID:</div>
    <div class="">{{profile.isdpAid}}</div>
</div>
<div class="row">
    <div class="col-md-3">Class:</div>
    <div class="">{{profile.profileClass}}</div>
</div>